@use"base/variables" as v;
@use"base/mixins" as m;
.bannerIMG{
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    @include m.tablet{
        height: 60rem;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
    }
}
.bannerIMG-galeria{
    width: 100%;
    height: 100vh;
    @include m.tablet{
        height: 100%;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
    }
}
.bannerIMG-gal{
    position: relative;
}
.bannerIMG-galeria-titulo{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    h2{
        color: white;
        position: absolute;
        justify-content: center;
        font-weight: 400;
        text-align: center;
        font-size: 4.5rem;
    }
}
.bannerIMG-galeria-opacidad{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.346);
}
.bannerIMG_div{
    width: 100%;
    height: 100%;
    display: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(../img/avanceObra/avanceDeObra1.webp);
    
}
.bannerIMG_div:nth-child(1){
    background-image: url(../img/avanceObra/avanceDeObra1.webp);
}
.bannerIMG_div:nth-child(2){
    background-image: url(../img/avanceObra/avanceDeObra2.webp);
}
.bannerIMG_div:nth-child(3){
    background-image: url(../img/avanceObra/avanceDeObra3.webp);
}
.bannerIMG_div:nth-child(4){
    background-image: url(../img/avanceObra/avanceDeObra4.webp);
}
.activo{
    display: inherit;
}
.fade {
    animation-name: fade;
    animation-duration: 2s;
  }
  
  @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }
.bannerIMG-financiamiento{
    background-color: v.$rojoClaro;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    @include m.tablet{
        border-left: 5px solid black;
    }
}
.financiamiento-desc{
    margin-top: 5rem;
    h2{
        color: white;
        font-size: 3rem;
        font-weight: 400;
        text-align: center;
        margin-bottom: 1rem;
        @include m.telefono{
            font-size: 4.5rem;
        }
        @include m.tablet{
            font-size: 5rem;
        }
    }
    p{
        text-align: center;
        color: white;
        font-size: 2rem;
        margin-bottom: 5rem;
        @include m.telefono{
            font-size: 2.5rem;
        }
        @include m.tablet{
            margin-bottom : 2rem;
            font-size: 3rem;
        }
        span{
            font-weight: 600;
        }
    }
}
.botonNegro{
    background-color: black;
}
.ubicacion_descripcion{
    padding: 3rem 0 3rem 0;
    width: 100%;
    display: grid;
    grid-template-rows: .25fr .75fr;
    background-color: black;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    flex-direction: column;
    @include m.desktop{
        gap: 2rem;
        display: flex;
        flex-direction: row;
    }
}
.ubicacion_descripcion-text{
    grid-row: 1/2;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: right;
    flex-basis: 40%;
    height: 100%;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    @include m.desktop{
        padding: 4rem 0;
        align-items: end;
        padding: 0;
        margin-right: 2rem;
    }
    a{
        margin-top: 2rem;
        padding:2rem;
    }
    p{
        width: 100%;
        text-align: justify;
        font-size: 1.7rem;
        @include m.telefono{
            text-align: center;
            font-size:2rem; 
        }
        @include m.desktop{
            text-align: right;
        }
    }
    h2{
        font-size: 3rem;
        text-align: center;
        font-weight: 600;
        margin-bottom: 1rem;
        @include m.telefono{
            margin-bottom: .5rem;
            font-size: 3rem;
            font-weight: 400;
        }
        @include m.desktop{
            text-align: right;
        }
    }
}
.ubicacion_descripcion-mapa{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 60%;
    @include m.desktop{
        padding: 6rem 0;
    }
    img{
        max-width: 1000px;
    }
}
.redesSociales{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.botonEnMapa{
    margin-top: 5rem;
    display: unset;
    @include m.desktop{
        display: none;
    }
}
.botonEnDesc{
    display: none;
    @include m.desktop{
        display: unset;
    }
}