@use"base/variables" as v;
@use"base/mixins" as m;
.descripcion{
    padding: 10rem 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-image: url("../img/rendes/PORTICO 02.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.desvanecido{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.753);
}
.divisionesDescripcion{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include m.tablet{
        flex-direction: row;
        justify-content: start;
    }

}
.descripcion-info{
    z-index: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    color: white;
    padding: 2rem 1rem;
    @include m.tablet{
        padding: 3rem;;
    }
    h2{
        font-size: 3.5rem;
        font-weight: 400;
    }
    .sub{
        margin-bottom: 1.5rem;
        font-weight: 400;
        text-align: center;
        font-size: 2rem;
        @include m.telefono{
            font-size: 2.5rem;
        }
        @include m.tablet{
            font-size: 3.5rem;
            text-align: right;
        }
    }
    p{
        text-align: left;
        font-size: 1.7rem;
        font-weight: 300;
        margin-bottom: 2rem;
        line-height: 2.5rem;
        @include m.telefono{
            font-size: 2rem;
            line-height: 3rem;
        }
    }
}
.descripcion-info-titulos{
    h2{
        text-align: center;
        font-size: 3.5rem;
        margin-bottom: 1rem;
        @include m.telefono{
            font-size: 6rem;
        }
        @include m.tablet{
            font-size: 8rem;
            text-align: right;
        }
    }

}

.listaBeneficios{
    span{
        position: relative;
        margin-right: 2rem;
        margin-left: 1rem;
        font-size: 2rem;
        font-weight: 500;
        @include m.telefono{
            font-size: 2.5rem;
        }
    }
    
    span::after{
        top: 2px;
        left: -6px;
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        background-color: v.$rojoClaro;
        z-index: -1;
        border-radius: 30px;
        @include m.telefono{
            top: 0;
            left: -10px;
            width: 35px;
            height: 35px;
        }
    }
}
.botonEnDescripcion{
    margin: 41px auto 0 auto;
    @include m.tablet{
        margin: 0;
    }
}