@use "variables" as v;

html{
    font-size: 62.5%;
    box-sizing: border-box;
    overflow-x: hidden;
}
*,
*:before,
*:after{
    box-sizing: inherit;
}
body{
    font-family: v.$fuentePrincipal;
    font-size: 1.6rem;
    overflow-x: hidden;
}
a,li,ul{
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
}
h1,h2,h3{

    font-family: v.$fuenteTitulos;
    margin:0;
}
h1{
    font-size: 6rem;
    
}
h2{
    font-size: 3rem;
}
h3{
    font-size: 3rem;
}
img{
    width: 100%;
}
p,a{
    margin: 0;
    padding: 0;
}
.contenedor{
    width: 95%;
    max-width: 120rem;
    margin: 0 auto;
}