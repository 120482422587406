@use"base/variables" as v;
@use"base/mixins" as m;
.banner{
    padding: 0;
    margin: 0;
    height: 100vh;
    background-image: url('../img/rendes/bannerPIC.webp');
    background-repeat: no-repeat;
    background-position: 70%;
    background-size: cover;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
}
.sombreado{
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.815) 0%, rgba(0,0,0,0) 100%);
    position: absolute;
    top: 0;
}
.banner_contenido{
    z-index: 2;
    width: 60rem;
    margin-left: 2rem;
    color: white;
    @include m.telefono{
        font-size: 4.5rem;
        margin-left: 4rem;
    }
    @include m.tablet{
        font-size: 4.5rem;
        margin-left: 10rem;
    }
    h1{
        font-size: 2.7rem;
        word-spacing: .5rem;
        letter-spacing: .1rem;
        margin-bottom: .5rem;
        font-weight: 100;
        @include m.telefono{
            font-size: 4.5rem;
        }
        span{
            letter-spacing: 5px;
            font-size: 5rem;
            font-weight: 900;
            @include m.telefono{
                font-size: 7rem;
            }
        }
    }
    p{
        font-size: 2rem;
        margin-bottom: 4rem;
        font-weight: 400;
        width: 40rem;
    }

}
.botonFormulario{
    text-align: center;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 2rem;
    padding: 1.5rem;
    background-color: v.$rojoClaro;
    transition: 1s ease ;
    width: 20rem;
}
.botonFormulario:hover{
    transform: scale(1.1);
    cursor: pointer;
}
.botonNavegador{
    padding: 1rem;
}