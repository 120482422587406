@use"base/mixins" as m;
.navegador{
    z-index: 20;
    display: flex;
    top: 0;
    height: 4rem;
    padding: 3rem .5rem;
    position: fixed;
    width: 100%;
    justify-content:space-between;
    align-items: center;
    background-color: rgb(255, 255, 255);
    @include m.telefono{
        height: 6rem;
        padding: 3.5rem 4rem;
    }
    .navegador_logo{
        width: 16rem;
    }
    .botonNavegador{
        font-size: 1.5rem;
        padding: 1rem .5rem;
        @include m.telefono{
            padding: 1rem 1.5rem;
            font-size: 2rem;
        } 
    }
}
.navegador_redes{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        white-space: break-spaces;
    }
}
