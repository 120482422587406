@use"base/variables" as v;
.formulario{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.654);
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
}
form{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem 5rem;
    height: 100%;
    max-width: 120rem;
    background-color: white;
    border-radius: 5px;
    z-index: 10;
    max-width: 80rem;
    overflow-y: scroll;
    overscroll-behavior: contain;
    h2{
        font-weight: 400;
        font-size: 4rem;
        max-width: 80rem;
        text-align: center;
    }
}
.form{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    display: unset;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.fondo{
    width: 100%;
    height: 100%;
    position: absolute;
}
.preguntas{
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    font-size: 2rem;
    width: 100%;

    input{
        border: 1px solid rgba(0, 0, 0, 0.42);
        height: 3.5rem;
    }
}
.botonFormularioC{
    background-color :  v.$rojoClaro;
    padding: 1rem;
    display: block;
    width: 100%;
    border-radius: .5rem;
    color: white;
    text-align: center;
    font-size: 2rem;
    margin-top: 3rem;
    transition: transform 1s ease;
}
.botonFormularioC:hover{
    color: black;
    transform: scale(1.02);
}
.cerrar{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 3rem;
}
.cerrar:hover{
    cursor: pointer;
}
.centrarForm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
}
.incorrecta{
    color: red;
}
.correctoSub{
    text-align: center;
    font-weight: 400;
    margin-top: 1rem;
}
.correctotext{
    margin-top: 1rem;
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
}