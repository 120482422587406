@use"base/variables" as v;
@use"base/mixins" as m;
.descripcionParque{
    justify-content: center;
    align-items: center;
    display: grid;
    background-color: white;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr ;
    height: auto;
    @include m.telefono{
        grid-template-rows: 1fr;
    }
    @include m.desktop{
        height: 60rem;
        display: flex;
    }
}   
.descripcionParque-listaCaracteristicas{
    margin: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include m.telefono{
        display: flex;
        flex-direction: row;
    }
    ul{
        padding: 0 .5rem;
        @include m.desktop{
            padding: 2rem;
        }
        li{
            color: black;
            display: flex;
            justify-content: center;
            align-items:center ;
            font-size: 1.7rem;
            font-weight: 400;
            margin: 1rem 0;
            @include m.telefono{
                justify-content: start;
            }
            svg{
                margin-right: 1rem;
                width: 24px;
            }
        }
        .ultimaAmenidad{
            margin-bottom: 0;
        }
    }
}  
.descripcionParque-imagen{
    
    max-height: none;
    width: 55%;
    height: 90%;
    display: none;
    background-image: url(../img/rendes/INTERIOR.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include m.desktop{
        display: unset;
    }
}
.descripcionParque-caracteristicas{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;
    @include m.desktop{
        margin-bottom: 0;
        width: 45%;
    }
    h2{
        width: 100%;
        text-align: center;
        font-size: 4rem;
    }
}
.descripcionParque-listaCaracteristicas{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 0;
    @include m.telefono{
        gap: 5rem;
    }
    
}  