@use"base/variables" as v;
footer{
    text-align: center;
    padding-top: 5rem;
    height: 20rem;
    background-color: v.$rojoClaro;
    h3{
        color: white;
        margin-top: 3rem;
        font-weight: 400;
    }
}
.footer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.linea{
    width: 100%;
    height: 5px;
    color: white;
    background: white;
    position: absolute;
    top: 19px;
}
.redesSociales{
    padding: 0 2rem;
    z-index: 1;
    background: #e9130c;
}
.footerDatos{
    p{
        margin-top: 2rem;
        color: white ;
        font-size: 2rem;
    }
}
.botonWhatsapp{
    z-index: 19;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    img{
        width: 100%;
        height: 100%;
    }
}
