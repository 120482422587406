@use"base/variables" as v;
@use"base/mixins" as m;
.descripcionConIconos{
    background-color: v.$rojoClaro;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8rem 0;
    flex-direction: column;
    margin-bottom: 3rem;
    @include m.desktop{
        margin-bottom: 0rem;
   }

}
.descripcionConIconos-Titulos{
    margin-bottom: 6rem;
    text-align: center;
    color: white;
    h2{
        font-weight: 600;
        font-size: 3rem;
        @include m.telefono{
            font-weight: 400;
            font-size: 4rem;
        }
    }
    p{
        font-size: 2rem;
    }
}
.descripcionConIconos_informacion{
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 25rem;
}
.descripcionConIconos_informacion-icono{
    padding-bottom: 2rem;
    position: relative;
    svg{
        z-index: 5;
        margin-bottom: 2rem;
    }
}
.descripcionConIconos_informacion-titulo{
    height: 100%;
    color: white;
    height: 100%;
    @include m.desktop{
        height: 8rem;
    }
    h3{
        font-weight: 100;
        font-size: 2rem;
        @include m.desktop{
            font-size: 3rem;
       }
    }
}
.descripcionConIconos_informacion-descripcion{
    color: white;
}
.iconsArea{
    flex-wrap: wrap;
    position: relative;
    display: flex;
    justify-content: center;
    gap: 7rem;
    align-items: center;
    width: 100%;
}
.circuloBlanco{
    z-index: -1;
    width: 90px;
    height: 90px;
    position: absolute;
    border-radius: 10rem;
    background-color: white;
    top: -40px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}